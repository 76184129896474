import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeadings"
import SEO from "../components/seo"

import { Helmet } from "react-helmet"
import styled from "styled-components"
import ProductPageComponent from "../components/ProductPageComponent"

import DiskImage1 from "../images/k16-white-bg-disk-thumbnail.jpg"
import DiskImage2 from "../images/k16-garage-image.jpg"
import DiskImage3 from "../images/k16-factory-image.jpg"
import DiskImage4 from "../images/k16-warehouse-image.jpg"

import CylindricalImage1 from "../images/k16-white-bg-cylindrical-thumbnail.jpg"
import CylindricalImage2 from "../images/KODIAK-K16-26-C-front-side-THUMB.jpg"
import CylindricalImage3 from "../images/KODIAK-K16-26-C-front-side-back-THUMB.jpg"
import CylindricalImage4 from "../images/KODIAK-K16-26-C-front-side-back-twist-THUMB.jpg"

import OrbitalImage1 from "../images/KODIAK-K16-28-O-front-THUMB.jpg"
import OrbitalImage2 from "../images/KODIAK-K16-28-O-front-side-THUMB.jpg"
import OrbitalImage3 from "../images/KODIAK-K16-28-O-front-side-back-THUMB.jpg"
import OrbitalImage4 from "../images/KODIAK-K16-28-O-front-side-back-twist-THUMB.jpg"

import DiskImage1Large from "../images/k16-white-bg-disk.jpg"
import DiskImage2Large from "../images/k16-garage-image.jpg"
import DiskImage3Large from "../images/k16-factory-image.jpg"
import DiskImage4Large from "../images/k16-warehouse-image.jpg"

import CylindricalImage1Large from "../images/k16-white-bg-cylindrical.jpg"
import CylindricalImage2Large from "../images/KODIAK-K16-26-C-front-side-LARGE.jpg"
import CylindricalImage3Large from "../images/KODIAK-K16-26-C-front-side-back-LARGE.jpg"
import CylindricalImage4Large from "../images/KODIAK-K16-26-C-front-side-back-twist-LARGE.jpg"

import OrbitalImage1Large from "../images/KODIAK-K16-28-O-front-LARGE.jpg"
import OrbitalImage2Large from "../images/KODIAK-K16-28-O-front-side-LARGE.jpg"
import OrbitalImage3Large from "../images/KODIAK-K16-28-O-front-side-back-LARGE.jpg"
import OrbitalImage4Large from "../images/KODIAK-K16-28-O-front-side-back-twist-LARGE.jpg"

import CylindricalImageThumb from "../images/cylindrical-3d-small.jpg"
import DiskImageThumb from "../images/disk-thumb.jpg"
import OrbitalImageThumb from "../images/orbital-thumb.jpg"
import OrbitalImage from "../images/orbital-deck-400.png"
import CylindricalImage from "../images/cylindrica-deck-imagel-400x.png"
import DiskImage from "../images/disk-deck-400.png"

const K16PageMain = styled.main`
  padding: 2rem;
`

const K16Page = () => {
  return (
    <>
      <SEO title="Floor Scrubbers: K16 Walk-Behind Industrial Cleaning Equipment - Kodiak Cleaning Equipment" />
      <Helmet>
        <title>
          Floor Scrubbers: K16 Walk-Behind Industrial Cleaning Equipment -
          Kodiak Cleaning Equipment
        </title>
        <description></description>
      </Helmet>
      <MainLayout>
        <PageHeading
          pageHeading="K16 Floor Scrubbers"
          // description="Here is the info about the K3 scrubbers and what they are used for"
        />
        <K16PageMain>
          <ProductPageComponent
            showOrbital="none"
            chooseTitle="Choose Your Scrub Deck"
            descriptionTitle1="Solution Tank Capacity:"
            descriptionTitle2="Run Time:"
            mainDiskImage={DiskImage1Large}
            mainCylindricalImage={CylindricalImage1Large}
            mainOrbitalImage={OrbitalImage1Large}
            thumbDiskImage1={DiskImage1}
            thumbDiskImage2={DiskImage2}
            thumbDiskImage3={DiskImage3}
            thumbDiskImage4={DiskImage4}
            thumbCylindricalImage1={CylindricalImage1}
            thumbCylindricalImage2={CylindricalImage2}
            thumbCylindricalImage3={CylindricalImage3}
            thumbCylindricalImage4={CylindricalImage4}
            thumbOrbitalImage1={OrbitalImage1}
            thumbOrbitalImage2={OrbitalImage2}
            thumbOrbitalImage3={OrbitalImage3}
            thumbOrbitalImage4={OrbitalImage4}
            DiskImage1Large={DiskImage1Large}
            DiskImage2Large={DiskImage2Large}
            DiskImage3Large={DiskImage3Large}
            DiskImage4Large={DiskImage4Large}
            CylindricalImage1Large={CylindricalImage1Large}
            CylindricalImage2Large={CylindricalImage2Large}
            CylindricalImage3Large={CylindricalImage3Large}
            CylindricalImage4Large={CylindricalImage4Large}
            OrbitalImage1Large={OrbitalImage1Large}
            OrbitalImage2Large={OrbitalImage2Large}
            OrbitalImage3Large={OrbitalImage3Large}
            OrbitalImage4Large={OrbitalImage4Large}
            diskTitle="K16 Disk"
            subTitle="Walk-Behind Medium Floor Scrubber"
            diskDescription="Reduce the cost to clean with the K16 Floor Scrubber.  We’ve made consistent cleaning and being budget conscious our top priorities while delivering a durable floor scrubber that will last for years.  Maintain cleanliness in Warehouses, Fitness Facilities, Fabrication Shops, and other applications.  The scrub brushes are installed and removed without tools to allow ease when switching between application’s needs.  The squeegee blades can be rotated or flipped without tools.  Ensure complete cleaning in a variety of sized applications with a reliable scrubber."
            diskCleaningPath="20 - 28in (51 - 71cm)"
            diskGallons="Sol: 16gal (60l) Rec: 16gal (60l) "
            diskRunTime=" Up to 3 hours"
            cylindricalTitle="K16 Cylindrical"
            cylindricalDescription="Reduce the cost to clean with the K16 Floor Scrubber.  We’ve made consistent cleaning and being budget conscious our top priorities while delivering a durable floor scrubber that will last for years.  Maintain cleanliness in Warehouses, Fitness Facilities, Fabrication Shops, and other applications.  The scrub brushes are installed and removed without tools to allow ease when switching between application’s needs.  The squeegee blades can be rotated or flipped without tools.  Ensure complete cleaning in a variety of sized applications with a reliable scrubber."
            cylindricalCleaningPath="20 - 28in (51 - 71cm)"
            cylindricalGallons="Sol: 16gal (60l) Rec: 16gal (60l) "
            cylindricalRunTime=" Up to 3 hours"
            orbitalTitle="K16 Orbital"
            orbitalDescription="Reduce the cost to clean with the K16 Floor Scrubber.  We’ve made consistent cleaning and being budget conscious our top priorities while delivering a durable floor scrubber that will last for years.  Maintain cleanliness in Warehouses, Fitness Facilities, Fabrication Shops, and other applications.  The scrub brushes are installed and removed without tools to allow ease when switching between application’s needs.  The squeegee blades can be rotated or flipped without tools.  Ensure complete cleaning in a variety of sized applications with a reliable scrubber."
            orbitalCleaningPath="20 - 28in (51 - 71cm)"
            orbitalGallons="Sol: 16gal (60l) Rec: 16gal (60l) "
            orbitalRunTime=" Up to 3 hours"
            DeckImageThumb1={DiskImageThumb}
            DeckImageThumb2={CylindricalImageThumb}
            // DeckImageThumb3={OrbitalImageThumb}
            DeckImage1Description="Disk"
            DeckImage2Description="Cylindrical"
            // DeckImage3Description="Orbital"
            DeckImageLarge1={DiskImage}
            DeckImageLarge2={CylindricalImage}
            DeckImageLarge3={OrbitalImage}
            // deckTitle="Why Disk"
            deckDescription1="Wavy / Irregular Floors"
            deckDescription2="Lowest Maintenance Cost"
            deckDescription3="Allows Pads"
            deckTitle2="Why Cylindrical"
            deckDescription4="Scrub & Sweep Simultaneously"
            deckDescription5="Tile & Grout Cleaning"
            deckDescription6="Rubber Surfaces & Track Fields"
            // deckTitle="Why Orbital"
            deckDescription7="Chemical Free Stripping"
            deckDescription8="70% Reduction in Water Usage"
            deckDescription9="Floor Finish Prep & Recoat"
            brochurePdf="../kodiak-walk-behind-sm.pdf"
            techPdf="../kodiak-k16-specsheet.pdf"
            partsPdf="https://www.dropbox.com/s/ouqlikq65u87kdw/k-k16-p-en.pdf?dl=0"
            operatorPdf="https://www.dropbox.com/s/qskm3bx3jwor4uq/k-k16-op-en.pdf?dl=0"
            hoodPdf="../kodiak-underthehood.pdf"
            mobileDiskImage={DiskImageThumb}
            mobileCylindricalImage={CylindricalImageThumb}
            mobileOrbitalImage={OrbitalImageThumb}
            mobileDiskTitle="Disk"
            mobileCylindricalTitle="Cylindrical"
            // mobileOrbitalTitle="Orbital"
            PmPdf="../K16-Series-PM.pdf"
            mtLink="https://www.dropbox.com/s/evrcuco63ko0478/daily-weekly-maintenance-3rd-v21306.pdf?dl=0"
          />
        </K16PageMain>
      </MainLayout>
    </>
  )
}

export default K16Page
